import exportList from "./exportList"
import myServiceOrderList from "./list"

const myServiceOrder = {
    path: '/myServiceOrder',
    component: resolve => require(['../../../../pages/admin/my_service_order'], resolve),
    redirect: '/myServiceOrder/list',
    children: [
        myServiceOrderList,
        exportList
    ],
    meta: {
        requireAuth: true
    }
}

export default myServiceOrder
