import suggestionList from "./list"

const suggestion = {
    path: '/suggestion',
    component: resolve => require(['@/pages/admin/suggestion'], resolve),
    redirect: '/suggestion',
    children: [
        suggestionList
    ],
    meta: {
        requireAuth: true
    }
}

export default suggestion