const Rbac = {
    path: '/rbac',
    component: resolve => require(['@/pages/admin/rbac'], resolve),
    redirect: '/rbac/user',
    children: [{
            path: "user",
            component: resolve => require(['@/pages/admin/rbac/user/index'], resolve),
        },
        {
            path: "role",
            component: resolve => require(['@/pages/admin/rbac/role/index'], resolve),
        },
        {
            path: "allocMenu",
            component: resolve => require(['@/pages/admin/rbac/role/allocMenu'], resolve),
        },
        {
            path: "menu",
            component: resolve => require(['@/pages/admin/rbac/menu/index'], resolve),
        },
        {
            path: "merchants",
            component: resolve => require(['@/pages/admin/rbac/merchants/index'], resolve),
        },
				{
					path: "agent",
					component: resolve => require(['@/pages/admin/rbac/agent/index'], resolve),
				}
    ],
    meta: {
        requireAuth: true
    }
};


export default Rbac