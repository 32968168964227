import capitalList from './list'
import capitaledit from './edit'
const capital = {
  path: '/capital',
  component: resolve => require(['@/pages/admin/capital'], resolve),
  redirect: '/capital/list',
  children: [
    capitalList,
    capitaledit
  ],
  meta: {
    requireAuth: true
  }
}

export default capital