import messageCategory from "./category"
import systemSet from './systemSet'
import payChannelInterface from "./payChannelInterface"
import log from "./log"
const system = {
    path: '/system',
    component: resolve => require(['@/pages/admin/system'], resolve),
    redirect: '/system/category',
    children: [
        messageCategory,
        systemSet,
        payChannelInterface,
        log
    ],
    meta: {
        requireAuth: true
    }
}

export default system