import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import PageIndex from '../pages'
import pageRouters from './pageRouters'
// let originPush = Router.prototype.push
// let originReplace = Vue.prototype.originReplace


Vue.use(Router)

// Router.prototype.push = function (location,res,rej) {
//   if (res && rej ) {
//     originPush.call(this,location,res,rej)
//   } else {
//     originPush.call(this,location,()=>{},()=>{})
//   }
// }

// Router.prototype.replace = function(location ,res,rej) {
//   if (res && rej) {
//     originReplace.call(this,location,res,rej)
//   } else {
//     originReplace.call(this,location,() => {},() => {})
//   }
// }


/* 加载进度 */
NProgress.configure({
  speed: 500,
  showSpinner: false
})
const routes = [
  {
    path: '/',
    component: PageIndex,
    children: [
      ...pageRouters
    ]
  }
]

//添加以下代码
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new Router({
  // mode:"history",
  routes: routes
})



router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
