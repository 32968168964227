import tradestatisticList from './tradestatistic'
import orderList from './order'
import consumption from './consumption'
import rechargeList from './rechargeList'
import serviceproducts_omt from './serviceproducts_omt'

const trading = {
  path: '/trading',
  component: resolve => require(['@/pages/admin/trading'], resolve),
  redirect: '/trading/consumption',
  children: [
    tradestatisticList,
    orderList,
    consumption,
    rechargeList,
    serviceproducts_omt
  ],
  meta: {
    requireAuth: true,
  },
}

export default trading