import financeList from './list'
import financeProductManage from './productManage'
import profitslist from './profitslist'
import withdrawlist from './withdrawlist'
const finance = {
  path: '/finance',
  component: resolve => require(['@/pages/admin/finance'], resolve),
  redirect: '/finance/list',
  children: [
    financeList,
    financeProductManage,
    profitslist,
    withdrawlist
  ],
  meta: {
    requireAuth: true
  }
}

export default finance
