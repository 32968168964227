const store = {
  state: {
    stepsNums: localStorage.getItem('stepsNums') || 1,
    menuIndex: sessionStorage.getItem('menuIndex') || 1,
    routePath: localStorage.getItem("routePaht") || ''
  },
  mutations: {
    setStepsNums (state) {
      state.stepsNums++
    },
    setMenuIndex (state) {
      state.menuIndex++
    },
    setRoutePath(state, path){
      state.routePath = path
    }
  }
}

export default store
