import businessmagList from './list'
import businessmagAdd from './add'
import businessmagedit from './edit'
import agentbanner from './agentbanner'
import addagentbanner from './agentbanner/addagentbanner'
import managebanner from './agentbanner/managebanner'
// 代理商消息
import agentMessage from './agentMessage'
import agentAddMessage from './agentMessage/addMessage'

import consumeList from "./consume";
const businessmag = {
  path: '/businessmag',
  component: resolve => require(['@/pages/admin/businessmag'], resolve),
  redirect: '/businessmag/list',
  children: [
    businessmagList,
    businessmagAdd,
    businessmagedit,
    managebanner, //广告管理
    agentbanner,
    addagentbanner,
    agentMessage,
    agentAddMessage,
    consumeList
  ],
  meta: {
    requireAuth: true
  }
}

export default businessmag