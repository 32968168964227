import admin from './pages/admin'
import login from './pages/login'
import resetPassword from './pages/reset-password'
import uploadcontract from './pages/uploadcontract'
import teamwork from './pages/teamwork'
import addTeam from './pages/addTeam'

const pageRouters = [
  admin,
  login,
  resetPassword,
  uploadcontract,
  teamwork,
  addTeam,
]

export default pageRouters
