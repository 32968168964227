// import aftersalesReplayList from './list'
const merchantRecord = {
    path: 'merchant_record',
    component: resolve => require(['@/pages/admin/aftersales/merchant/index.vue'], resolve),
    meta: {
        requireAuth: true
    },
    children: [
    ]
}

export default merchantRecord