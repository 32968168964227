import merchantList from "./merchant"
import agent from "./agent"
import agentAdd from "./agent/add"
import agentEdit from "./agent/edit"

const channel = {
    path: '/channel',
    component: resolve => require(['@/pages/admin/channel/index'], resolve),
    redirect: '/merchant/list',
    children: [
        merchantList,
        agent,
        agentAdd,
        agentEdit,
    ],
    meta: {
        requireAuth: true
    }
}

export default channel