import advertising from './advertising/advertising'
import advertisingAdd from "./advertising/add"
import agentbanner from "./agentbanner/list"
import managebanner from "./agentbanner/managebanner/list"
import manageBannerAdd from "./agentbanner/managebanner/add"



const admin = {
    path: '/ad',
    component: resolve => require(['@/pages/admin/ad/index.vue'], resolve),
    redirect: '/ad/advertising',
    children: [
        advertising,
        advertisingAdd,
        agentbanner,
        managebanner,
        manageBannerAdd
    ],
    meta: {
        requireAuth: true
    }
}

export default admin