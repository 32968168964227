const addagentbanner = {
  path: 'agentbanner/addagentbanner',
  component: resolve => require(['@/pages/admin/businessmag/agentbanner/addagentbanner'], resolve),
  meta: {
    requireAuth: true
  },
  children: [
    // aftersalesReplayList,
  ]
}

export default addagentbanner