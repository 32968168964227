
import foundCreate from './create'
import  foundList from './list'
import foundForTheRecord from './forTheRecord'

const found = {
  path: 'found',
  component: resolve => require(['../../../../../../../pages/admin/merchant/signing/yftAlipay/found'], resolve),
  redirect: 'found/list',
  meta: {isFound: true},
  children: [
    foundCreate,
    foundList,
    foundForTheRecord
  ],
}

export default found