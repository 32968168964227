import brokerageAudit  from "@/router/pages/admin/brokerage_audit/brokerage_audit";

const storageAudit = {
    path: '/brokerage_audit',
    component: resolve => require(['@/pages/admin/brokerage_audit'], resolve),
    redirect: '/',
    children: [
        brokerageAudit,
    ],
    meta: {
        requireAuth: true
    },
}

export default storageAudit