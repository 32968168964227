import auditApplyUnfreezingFunds from './auditApplyUnfreezingFunds' //解冻审批
import closeapprove from './closeapprove' //关停审批
const riskMerchantHandle = {
  path: '/riskMerchantHandle',
  component: resolve => require(['@/pages/admin/riskMerchantHandle'], resolve),
  redirect: '/riskMerchantHandle/auditApplyUnfreezingFunds',
  children: [
	auditApplyUnfreezingFunds,//解冻资金审批
	closeapprove,//关停商户审批
  ],
  meta: {
    requireAuth: true
  },
}
export default riskMerchantHandle