import alipayAccessPay from './accessPay'
import alipayAccredit from './accredit'
import alipayFound from './found'
import alipayLaunch from './launch'
import alipayRate from './rate'
import alipaySubLedger from './subLedger'
import merchantTest from './merchantTest'

const alipay = {
  path: 'alipay',
  component: resolve => require(['@/pages/admin/merchant/signing/yftAlipay'], resolve),
  redirect: 'alipay/found',
  children: [
    alipayFound,
    alipayAccessPay,
    alipayLaunch,
    alipaySubLedger,
    alipayRate,
    alipayAccredit,
    merchantTest
  ]
}

export default alipay