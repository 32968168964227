const merchantVideo = {
    path: 'merchant_video',
    component: resolve => require(['@/pages/admin/aftersales/merchantVideo/index.vue'], resolve),
    meta: {
        requireAuth: true
    },
    children: [

    ]
}

export default merchantVideo