import afterSalesList from "@/router/pages/admin/agent/aftersales";

const agent = {
    path: '/agent',
    component: resolve => require(['@/pages/admin/agent'], resolve),
    redirect: '/agent/afttersales',
    children: [
        afterSalesList
    ],
    meta: {
        requireAuth: true
    },
}
export default agent