
import foundCreate from './create'
import foundList from './list'
import foundSuccess from './success'
import foundNewPage from './newPage'
import foundWxAuthorization from './wxAuthorization'
import foundForTheRecord from './forTheRecord'

const found = {
  path: 'found',
  component: resolve => require(['@/pages/admin/merchant/signing/yftWeChat/found'], resolve),
  redirect: 'found/list',
  meta: {isFound: true},
  children: [
    foundCreate,
    foundList,
    foundSuccess,
    foundNewPage,
    foundWxAuthorization,
    foundForTheRecord
  ],
}

export default found